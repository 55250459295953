import { render, staticRenderFns } from "./TabContentPanel.vue?vue&type=template&id=d155c2f4&scoped=true"
import script from "./TabContentPanel.vue?vue&type=script&lang=js"
export * from "./TabContentPanel.vue?vue&type=script&lang=js"
import style0 from "./TabContentPanel.vue?vue&type=style&index=0&id=d155c2f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d155c2f4",
  null
  
)

export default component.exports