import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {http} from "@/plugins/http";
import "./styles/public.scss";
import Vant from 'vant';
import 'vant/lib/index.css';
import "./utils/rem";

Vue.config.productionTip = false
Vue.use(Vant);
Vue.prototype.$axios = http;

const app = new Vue({
    router,
    store,
    render: h => h(App)
})
app.$mount('#app')
