<script>
import {mapMutations} from "vuex";


export default {
  methods: {
    ...mapMutations(["logout"]),
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      if(this.$store.state.user.userInfo) {
        this.$dialog.confirm({message: "确认退出登录？", title: "退出登录"}).then(_ => {
          this.logout();
        }).catch(_ => {})
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="nav-header flex justify-between items-center h-14 border-b bg-white fixed top-0 left-0 right-0">
      <div class="left text-blue-500 absolute left-6" v-if="$route.meta.canBack" @click="onClickLeft">
        <van-icon name="arrow-left" />
        <span>返回</span>
      </div>
      <div class="title absolute left-1/2 -translate-x-1/2 font-bold text-xl">
        <span>{{ this.$route.meta.title }}</span>
      </div>
      <div class="right absolute right-6" v-if="$store.state.user.userInfo">
        <span @click="onClickRight" class="text-blue-500">{{$store.state.user.userInfo.phone}}</span>
      </div>
    </div>
    <div class="nav-header_placeholder h-14">

    </div>
  </div>
</template>

<style scoped lang="scss">

</style>