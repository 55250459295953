<script>
import TabContentPanel from "@/components/TabContentPanel.vue";

export default {
  name: "BrokerageDetails",
  components: {TabContentPanel},
  data() {
    return {
      page: 1,
      list: []
    }
  },
  mounted() {
    this.getList();
    this.scrollTop();
  },
  methods: {
    onLoad() {
    },
    scrollTop() {
      if(this.$refs.list) {
        this.$refs.list.scrollTo({top: 0});
      }
    },
    async getList() {
      const res = await this.$axios.get("/commission/getUserCommissionList", {
        params: {
          page: this.page,
          _showLoading: true
        }
      });
      this.list = res.data || [];
      let other = res.other_data;
      let status_array = other.status_array;
      if (other && status_array) {
        for (let item of this.list) {
          item.status_display = status_array[item.status];
        }
      }
    },
    getColor(status) {
      switch (status) {
        case 1:
          return "#0f9a0f";
        case 2:
          return "#1b80e5";
        case 3:
          return "#525050"
      }
    }
  }
}
</script>

<template>
  <div class="h-full">
    <!--    <TabContentPanel-->
    <!--        :titles="[{title: '昵称', key: 'username'}, {title: '状态', key: 'status'}, {title: '分佣金额', key: 'commission_amount'}, {title: '解冻时间', key: 'grant_time'}]"-->
    <!--        :list="list"-->
    <!--        @onLoad="onLoad"-->
    <!--        ref="tabContentPanel"-->
    <!--    ></TabContentPanel>-->
    <div class="tab-content-panel h-full flex flex-col">
      <div class="card-container h-full overflow-y-auto  rounded-3xl">
        <div class="list flex-1" ref="list" v-if="list && list.length > 0">
          <van-list>
            <div class="cell px-3 py-6 bg-white rounded-3xl mb-3" v-for="(item, index) in list" :key="index">
              <div class="cell-content mb-3 pb-3 border-b">
                <div class="flex justify-between">
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="row">
                      <div class="col">总计分佣金额：{{ item.issued_amount }}/{{item.total_amount}}元</div>
                      <!--                    <div class="col flex items-center">-->
                      <!--                      <span>总计分佣金额：</span>-->
                      <!--                      <div class="bg-amber-500 text-center w-fit px-3 py-1 rounded text-gray-50">-->
                      <!--                        <span style="letter-spacing: 0.1rem">800/10000元</span>-->
                      <!--                      </div>-->
                      <!--                    </div>-->
                    </div>
                    <div class="row">
                      <div class="col">下次分佣金额：{{ item.next_commission_amount === '-' ? item.next_commission_amount : item.next_commission_amount + '元' }}</div>
                    </div>
                    <div class="row">
                      <div class="col">下次分佣时间：{{ item.next_commission_date }}</div>
                    </div>
                  </div>
                  <div>
                    <!--                  <div class="px-8 py-4 bg-blue-500 text-white rounded-2xl shadow-md">结算中</div>-->
                    <van-circle :value="Number(item.issued_num / item.total_num) * 100" :speed="100" layer-color="#DDD" :color="getColor(item.status)">
                      <div class="h-full flex flex-col justify-center items-center">
                        <p class="text-lg">{{ item.status_display }}</p>
                        <p class="text-sm" style="letter-spacing: 0.08rem">{{ item.issued_num }}/{{ item.total_num }}次</p>
                      </div>
                    </van-circle>
                  </div>
                </div>
              </div>
              <div class="text-gray-400 flex justify-between product-content text-sm">
                <p class="mr-3">购买人：{{ item.username }}</p>
                <p class="flex-1 truncate">商品：{{ item.package_name }}</p>
              </div>
            </div>
          </van-list>
        </div>
        <div v-else class="flex-1 bg-white h-full rounded-3xl shadow-md">
          <van-empty description="暂无信息" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cell-content {
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .col {
      flex: 1;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
}

.product-content {
  width: 100%;
  .row {
    flex: 1;
    .col {
      flex: 1;
    }
  }
}
</style>