import {StorageKeys} from "@/store/keys";
import {http} from "@/plugins/http";
import {Toast} from "vant";

export const userModule = {
    state: {
        userInfo: null,
        token: localStorage.getItem(StorageKeys.TOKEN) ? localStorage.getItem(StorageKeys.TOKEN) : null,
        refreshToken: localStorage.getItem(StorageKeys.REFRESH_TOKEN) ? localStorage.getItem(StorageKeys.REFRESH_TOKEN) : null
    },
    mutations: {
        setToken(state, token) {
            localStorage.setItem(StorageKeys.TOKEN, token);
            state.token = token;
        },
        setRefreshToken(state, token) {
            localStorage.setItem(StorageKeys.REFRESH_TOKEN, token);
            state.refreshToken = token;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        logout() {
            localStorage.removeItem(StorageKeys.TOKEN);
            localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
            location.reload();
        }
    },
    actions: {
        async getUserInfo({commit}) {
            try {
                const res = await http.get("/commission/getUserInfo");
                commit("setUserInfo", res.data);
            }catch (e) {
                commit("setUserInfo", null);
                Toast({message: "获取用户信息失败！", type: "fail"})
            }
        }
    },
}