import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";

Vue.use(VueRouter)
const whiteViewList = [
    "/login"
];
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "个人中心"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      hideHeader: true
    }
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/WithdrawView.vue"),
    meta: {
      title: "提现",
      canBack: true
    }
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("../views/PromotionView.vue"),
    meta: {
      title: "返佣计划",
      canBack: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(whiteViewList.includes(to.path)) {
    return next();
  }
  let token = store.state.user.token;
  if(token) {
    store.dispatch("getUserInfo").then(() => {
      next();
    })
  } else {
    next({path: "/login"})
  }

})

export default router
