import Vue from 'vue'
import Vuex from 'vuex'
import {userModule} from "@/store/modules/user";
import {headerModule} from "@/store/modules/header";
import {publicModule} from "@/store/modules/public";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userModule,
    header: headerModule,
    public: publicModule
  }
})
