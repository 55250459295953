<script>
export default {
  name: "UserInfo",
};
</script>

<template>
  <div class="w-full bg-white flex justify-between p-7 items-center">
    <div class="flex-1 flex">
      <div class="w-12 h-12">
        <van-image :alt="$store.state.user.userInfo.username" :src="$store.state.user.userInfo.head_url" height="100%" round width="100%" />
      </div>
      <div class="ml-2 h-14 flex-col flex justify-center">
        <p class="text-lg">昵称:{{ $store.state.user.userInfo.username }}</p>
        <p class="text-xs text-gray-500">手机号:{{ $store.state.user.userInfo.phone }}</p>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <van-button type="primary" size="small" @click="$router.push('/withdraw')">申请提现</van-button>
      <van-button type="info" size="small" @click="$router.push('/promotion')">我要推广</van-button>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
