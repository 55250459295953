export const publicModule = {
    state: {
        loading: false,
    },
    mutations: {
        // 展示loading的方法
        showLoading(state) {
            state.loading = true
        },
        // 隐藏loading的方法
        hideLoading(state) {
            state.loading = false
        }
    },
}