<script>
import TabContentPanel from "@/components/TabContentPanel.vue";

export default {
  name: "Subordinates",
  components: {TabContentPanel},
  data() {
    return {
      list: [],
      page: 1,
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    onLoad() {

    },
    async getList() {
      const res = await this.$axios.get("/commission/getUserSubordinateList", {params: {page: this.page, _showLoading: true}});
      this.list = res.data.data;
    }
  }
}
</script>

<template>
  <div class="subordinates h-full">
    <TabContentPanel
        :titles="[{title: '昵称', key: 'username'}, {title: '注册时间', key: 'created_at'}]"
        :list="list"
        @onLoad="onLoad"
        ref="tabContentPanel"
    ></TabContentPanel>
  </div>
</template>

<style lang="scss" scoped>

</style>