<script>
export default {
  name: "PriceCount"
}
</script>

<template>
  <div class="price-count">
    <div class="flex justify-between p-2 bg-white border">
      <div class="flex flex-col text-center flex-1 border-r">
        <span class="font-bold">可用金额</span>
        <span class="font-bold">{{ $store.state.user.userInfo.balance }}</span>
      </div>
      <div class="flex flex-col text-center flex-1">
        <span class="font-bold">冻结金额</span>
        <span class="font-bold">{{ $store.state.user.userInfo.freeze_balance }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>