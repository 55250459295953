import axios from "axios";
import store from "@/store";
import {Toast} from "vant";
import {StorageKeys} from "@/store/keys";
import router from "@/router";

export const http = axios.create({
    baseURL: "https://wzapi.zhangcaijing.net/api/"
    // baseURL: "http://testwzapi.zhangcaijing.net/api"
    // baseURL: "/api"
});

http.interceptors.request.use(
    function (config) {
        config.headers["s-token"] = store.state.user.token;
        config.headers["device"] = "mp";
        let showLoading = config.params?._showLoading || false;
        if(showLoading) {
            store.commit("showLoading");
        }
        if(config.params) {
            delete config.params._showLoading;
        }
        return config;
    },
    function (error) {
        store.commit("hideLoading");
        return Promise.reject(error);
    }
)

http.interceptors.response.use(
    function (response) {
        store.commit("hideLoading");
        if(response.data.code == 10001 || response.data.code == 0 || response.data.code == 10000) {
            return response.data;
        }
        if(response.data.code == 10010) {
            localStorage.removeItem(StorageKeys.TOKEN);
            localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
            router.replace("/login");
        }
        Toast({message: response.data.msg || "发送错误", type: "fail"});
        return Promise.reject(response.data);
    },
    function (error) {
        store.commit("hideLoading");
        return Promise.reject(error)
    }
)