<script>
import {copyToClipboard} from "@/utils/copyToClipboard";

export default {
  name: "Contact",
  methods: {
  }
}
</script>

<template>
  <div class="contact shadow-md border right-6 bottom-12">
    <a href="tel:13107873675"><img src="@/assets/kf.png" alt="联系客服"></a>
  </div>
</template>

<style scoped>
.contact {
  position: fixed;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #FFF;
  padding: 0.2rem;
  z-index: 11;
}

.contact img {
  width: 100%;
}
</style>